
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";

const NewsletterSection = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const { error } = await supabase.functions.invoke('subscribe-sendfox', {
        body: { email }
      });

      if (error) throw error;

      toast({
        title: "Thank you for subscribing!",
        description: "You'll receive updates about the book launch soon.",
      });
      setEmail("");
    } catch (error) {
      console.error('Newsletter subscription error:', error);
      toast({
        title: "Error",
        description: "There was a problem subscribing you. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="py-16 bg-[#1A1F2C] text-white w-full">
      <div className="container mx-auto px-6">
        <div className="text-center max-w-3xl mx-auto">
          <h2 className="text-3xl font-avenir font-bold mb-6">
            Get Early Access to the Book
          </h2>
          <p className="text-gray-300 mb-8">
            Join the waitlist to be notified when "Sharks, Snakes & Business Partners" 
            is available and receive exclusive pre-launch content.
          </p>
          
          <form onSubmit={handleSubmit} className="space-y-3">
            <div className="flex flex-col sm:flex-row gap-3 max-w-md mx-auto">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="flex-1 px-4 py-3 rounded-lg border border-gray-700 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-amber-500/20"
                required
                disabled={isSubmitting}
              />
              <button
                type="submit"
                className="px-6 py-3 bg-amber-500 text-white rounded-lg hover:bg-amber-600 transition-colors disabled:opacity-50"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Subscribing..." : "Subscribe"}
              </button>
            </div>
            <p className="text-sm text-gray-400 mt-2">
              No spam, unsubscribe anytime.
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default NewsletterSection;
