
import { Routes, Route } from "react-router-dom";
import Index from "@/pages/Index";
import Articles from "@/pages/Articles";
import Article from "@/pages/Article";
import Admin from "@/pages/Admin";
import AdminArticleEdit from "@/pages/AdminArticleEdit";
import About from "@/pages/About";
import Book from "@/pages/Book";
import Home2 from "@/pages/Home2";
import Contact from "@/pages/Contact";
import Links from "@/pages/Links";
import Login from "@/pages/Login";
import { Toaster } from "@/components/ui/sonner";
import { ProtectedRoute } from "@/components/auth/ProtectedRoute";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/articles/:slug" element={<Article />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        } />
        <Route path="/admin/articles/:id" element={
          <ProtectedRoute>
            <AdminArticleEdit />
          </ProtectedRoute>
        } />
        <Route path="/about" element={<About />} />
        <Route path="/book" element={<Book />} />
        <Route path="/home2" element={<Home2 />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/links" element={<Links />} />
      </Routes>
      <Toaster />
    </>
  );
}

export default App;
