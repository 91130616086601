
import { useEffect } from "react";
import Navigation from "@/components/Navigation";
import Hero2 from "@/components/Hero2";
import AboutBookSection from "@/components/AboutBookSection";
import TestimonialsSection from "@/components/TestimonialsSection";
import NewsletterSection from "@/components/NewsletterSection";
import Footer from "@/components/Footer";

const Home2 = () => {
  useEffect(() => {
    document.title = "Sharks, Snakes & Business Partners | Cody McLain";
  }, []);

  return (
    <div className="min-h-screen bg-background">
      <Navigation />
      <Hero2 />
      <AboutBookSection />
      <TestimonialsSection />
      <NewsletterSection />
      <Footer />
    </div>
  );
};

export default Home2;
