
import { Check } from "lucide-react";

const AboutBookSection = () => {
  const benefits = [
    "Learn to spot warning signs in potential business partners",
    "Master the art of building trust without being naive",
    "Develop secure contracts that protect your interests",
    "Navigate difficult conversations with confidence",
    "Resolve conflicts before they damage your business",
    "Build lasting partnerships based on mutual respect"
  ];

  return (
    <section className="py-24 bg-white">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row gap-12">
          <div className="md:w-1/2">
            <span className="inline-block px-4 py-1.5 mb-6 text-sm font-medium bg-amber-100 text-amber-800 rounded-full">
              About The Book
            </span>
            <h2 className="text-4xl font-avenir font-bold mb-6">
              Navigate Business Relationships With Confidence
            </h2>
            <p className="text-gray-700 mb-6">
              In today's complex business landscape, partnerships can make or break your success. 
              This essential guide provides proven strategies to help entrepreneurs, executives, 
              and business owners identify potential issues before they become problems.
            </p>
            <p className="text-gray-700 mb-8">
              Drawing from real-world case studies and decades of business experience, 
              "Sharks, Snakes & Business Partners" offers practical advice for building 
              sustainable, profitable business relationships while protecting yourself from 
              common pitfalls.
            </p>
            
            <div className="mb-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                {benefits.map((benefit, index) => (
                  <div key={index} className="flex items-start">
                    <Check className="h-5 w-5 text-green-500 mr-2 mt-1 flex-shrink-0" />
                    <span className="text-gray-700">{benefit}</span>
                  </div>
                ))}
              </div>
            </div>
            
            <button className="px-8 py-3 bg-amber-500 text-white rounded-lg hover:bg-amber-600 transition-colors">
              Get Notified on Release
            </button>
          </div>
          
          <div className="md:w-1/2 bg-gray-50 rounded-2xl p-8">
            <h3 className="text-2xl font-medium mb-6">What You'll Learn</h3>
            
            <div className="space-y-6">
              <div className="bg-white p-5 rounded-xl shadow-sm">
                <h4 className="font-bold text-lg mb-2 text-red-500">Red Flag Recognition</h4>
                <p className="text-gray-700">
                  Develop a sixth sense for spotting problematic behaviors and situations 
                  before they impact your business.
                </p>
              </div>
              
              <div className="bg-white p-5 rounded-xl shadow-sm">
                <h4 className="font-bold text-lg mb-2 text-amber-500">Trust Building Framework</h4>
                <p className="text-gray-700">
                  A practical system for establishing and maintaining trust while verifying 
                  commitments and promises.
                </p>
              </div>
              
              <div className="bg-white p-5 rounded-xl shadow-sm">
                <h4 className="font-bold text-lg mb-2 text-green-500">Protection Strategies</h4>
                <p className="text-gray-700">
                  Legal, financial, and operational safeguards to implement before 
                  entering any business relationship.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBookSection;
