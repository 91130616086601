
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Shield, AlertTriangle, Users } from "lucide-react";

const Hero2 = () => {
  const headingRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) observer.observe(headingRef.current);
    if (textRef.current) observer.observe(textRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <section className="w-full min-h-screen bg-gradient-to-b from-[#1A1F2C] to-[#233144] text-white">
      <div className="container mx-auto px-6 py-20 md:py-32 flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 md:pr-12 mb-12 md:mb-0">
          <span className="inline-block px-4 py-1.5 mb-6 text-sm font-medium bg-red-500/20 text-red-400 rounded-full animate-fade-in">
            New Book Coming Soon
          </span>
          <h1
            ref={headingRef}
            className="animate-on-scroll text-5xl md:text-6xl font-avenir font-bold mb-6 leading-tight"
          >
            <span className="text-red-400">Sharks, Snakes</span> & <br />
            <span className="text-amber-400">Business Partners</span>
          </h1>
          <p
            ref={textRef}
            className="animate-on-scroll text-lg md:text-xl text-gray-300 max-w-2xl mb-8"
          >
            Learn how to identify red flags, build trust, and protect yourself in business. 
            Navigate partnerships with confidence and avoid costly mistakes.
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <div className="bg-white/10 p-4 rounded-lg flex flex-col items-center text-center">
              <Shield className="h-8 w-8 text-red-400 mb-2" />
              <h3 className="font-medium">Identify Red Flags</h3>
            </div>
            <div className="bg-white/10 p-4 rounded-lg flex flex-col items-center text-center">
              <Users className="h-8 w-8 text-amber-400 mb-2" />
              <h3 className="font-medium">Build Trust</h3>
            </div>
            <div className="bg-white/10 p-4 rounded-lg flex flex-col items-center text-center">
              <AlertTriangle className="h-8 w-8 text-green-400 mb-2" />
              <h3 className="font-medium">Protect Yourself</h3>
            </div>
          </div>
          
          <div className="flex flex-col sm:flex-row gap-4 animate-fade-in">
            <button className="px-8 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors">
              Pre-order Now
            </button>
            <Link 
              to="/book" 
              className="px-8 py-3 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors"
            >
              Learn More
            </Link>
          </div>
        </div>
        
        <div className="md:w-1/2 relative">
          <div className="bg-gradient-to-br from-red-500/20 to-amber-500/20 rounded-2xl p-1">
            <div className="bg-[#1A1F2C] rounded-2xl p-6 relative">
              <div className="aspect-[3/4] relative overflow-hidden rounded-lg shadow-2xl transform rotate-3 hover:rotate-0 transition-transform duration-300">
                <img
                  src="/lovable-uploads/7cc630c8-abdb-4eb6-9673-b3bdecfc3371.png"
                  alt="Book Cover: Sharks, Snakes & Business Partners"
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent flex items-end">
                  <div className="p-6">
                    <h3 className="text-xl font-bold text-white">Coming Fall 2025</h3>
                    <p className="text-gray-300">Sign up for early access</p>
                  </div>
                </div>
              </div>
              
              <div className="absolute top-4 right-4 bg-red-500 text-white text-sm font-bold px-3 py-1 rounded-full transform rotate-12">
                New
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero2;
