
import { Star } from "lucide-react";

const TestimonialsSection = () => {
  const testimonials = [
    {
      quote: "This book saved me from making a $200,000 mistake with the wrong business partner. The red flag checklist alone is worth 10x the price.",
      author: "Sarah Johnson",
      title: "CEO, TechStart Ventures",
      stars: 5
    },
    {
      quote: "A must-read for anyone considering a business partnership. I wish I had this knowledge before my first failed venture.",
      author: "Michael Chang",
      title: "Serial Entrepreneur",
      stars: 5
    },
    {
      quote: "Finally, practical advice that doesn't just tell you to 'trust your gut.' The frameworks in this book are game-changers.",
      author: "Jennifer Wu",
      title: "Business Attorney",
      stars: 5
    }
  ];

  return (
    <section className="py-24 bg-gray-50">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16 max-w-3xl mx-auto">
          <span className="inline-block px-4 py-1.5 mb-6 text-sm font-medium bg-purple-100 text-purple-800 rounded-full">
            Early Reviews
          </span>
          <h2 className="text-4xl font-avenir font-bold mb-6">
            What Business Leaders Are Saying
          </h2>
          <p className="text-gray-700">
            From startup founders to seasoned executives, the principles in "Sharks, Snakes & Business Partners" 
            have helped hundreds of business leaders build better partnerships.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-white p-8 rounded-xl shadow-sm">
              <div className="flex mb-4">
                {Array.from({ length: testimonial.stars }).map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-yellow-400 fill-current" />
                ))}
              </div>
              
              <p className="text-gray-700 mb-6 italic">"{testimonial.quote}"</p>
              
              <div>
                <p className="font-bold">{testimonial.author}</p>
                <p className="text-gray-500 text-sm">{testimonial.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
